import { useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation, useQueryClient } from "react-query";
import SettingsAddEditForm from "../../components/forms/SettingsAddEditForm";
import {
  fetchCategoryPricing,
  fetchCoatingOptions,
  createMeasurementCategory,
  updateMeasurementCategory,
  createCoatingOption,
  updateCoatingOption,
} from "../../services/measurementApi";
import { fetchAllWages, createWage, updateWage } from "../../services/wageApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import moment from "moment";

export default function ConfigurationSettings() {
  const [selectedConfig, setSelectedConfig] = useState("measurementCategory");
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formMode, setFormMode] = useState("Add");

  const queryClient = useQueryClient();

  // Queries for configurations
  const { data: categoryData, isLoading: isCategoryLoading } = useQuery(
    "measurementCategory",
    fetchCategoryPricing,
    {
      enabled: selectedConfig === "measurementCategory",
      staleTime: Infinity,
    }
  );

  const { data: coatingData, isLoading: isCoatingLoading } = useQuery(
    "coatingOptions",
    fetchCoatingOptions,
    {
      enabled: selectedConfig === "coatingOptions",
      staleTime: Infinity,
    }
  );

  const { data: wageResponse, isLoading: isWageLoading } = useQuery(
    "fetchWages",
    fetchAllWages,
    {
      enabled: selectedConfig === "wage",
      staleTime: Infinity,
    }
  );
  const rows =
    selectedConfig === "measurementCategory"
      ? categoryData || []
      : selectedConfig === "coatingOptions"
      ? coatingData || []
      : selectedConfig === "wage" && wageResponse && wageResponse?.length > 0 // Ensure wageResponse exists and is not empty
      ? wageResponse
      : [];

  const columns =
    selectedConfig === "measurementCategory"
      ? [
          {
            field: "categoryName",
            headerName: "Category Name",
            editable: true,
            flex: 1,
          },
          {
            field: "interiorPricePerSqft",
            headerName: "Interior Price (sqft)",
            editable: true,
            flex: 1,
          },
          {
            field: "exteriorPricePerSqft",
            headerName: "Exterior Price (sqft)",
            editable: true,
            flex: 1,
          },
        ]
      : selectedConfig === "coatingOptions"
      ? [
          {
            field: "coatingType",
            headerName: "Coating Type",
            editable: true,
            flex: 1,
          },
          {
            field: "pricePerSqft",
            headerName: "Price (sqft)",
            editable: true,
            flex: 1,
          },
        ]
      : [
          {
            field: "fullDayWage",
            headerName: "Full Day Wage",
            editable: true,
            flex: 1,
          },
          {
            field: "halfDayWage",
            headerName: "Half Day Wage",
            editable: true,
            flex: 1,
          },
          {
            field: "overtimeWage",
            headerName: "Overtime Wage",
            editable: true,
            flex: 1,
          },
          {
            field: "standardHoursPerDay",
            headerName: "Standard Hours/Day",
            editable: true,
            flex: 1,
          },
          {
            field: "maxHoursPerDay",
            headerName: "Max Hours/Day",
            editable: true,
            flex: 1,
          },
          {
            field: "adjustmentMinutes",
            headerName: "Adjustments (in minutes)",
            editable: true,
            flex: 1,
          },
          {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            valueGetter: (params) => {
              if (params) {
                return moment(params).format("YYYY-MM-DD"); // Use params.value directly
              }
              return "N/A"; // Fallback if createdDate is not available
            },
          },
          {
            field: "updatedDate",
            headerName: "Updated Date",
            flex: 1,
            valueGetter: (params) => {
              if (params) {
                return moment(params).format("YYYY-MM-DD"); // Use params.value directly
              }
              return "N/A"; // Fallback if createdDate is not available
            },
          },
        ];

  // Mutations for add, edit, and delete operations
  const { mutate: saveConfig, isLoading: isSaving } = useMutation(
    async (data) => {
      if (formMode === "Add") {
        if (selectedConfig === "measurementCategory")
          return createMeasurementCategory(data);
        if (selectedConfig === "coatingOptions")
          return createCoatingOption(data);
        if (selectedConfig === "wage") return createWage(data);
      } else {
        if (selectedConfig === "measurementCategory")
          return updateMeasurementCategory(data.id, data);
        if (selectedConfig === "coatingOptions")
          return updateCoatingOption(data.id, data);
        if (selectedConfig === "wage") return updateWage(data.id, data);
      }
    },
    {
      onSuccess: () => {
        if (selectedConfig === "wage")
          queryClient.invalidateQueries("fetchWages");
        if (selectedConfig === "coatingOptions")
          queryClient.invalidateQueries("coatingOptions");
        if (selectedConfig === "measurementCategory")
          queryClient.invalidateQueries("measurementCategory");
        setIsFormOpen(false);
      },
    }
  );

  const handleAddClick = () => {
    setFormMode("Add");
    const initialValues = {};
    columns.forEach((col) => {
      if (col.editable) initialValues[col.field] = "";
    });
    setFormValues(initialValues);
    setValidationErrors({});
    setIsFormOpen(true);
  };

  const handleEditClick = (row) => {
    setFormMode("Edit");
    setFormValues(row);
    setValidationErrors({});
    setIsFormOpen(true);
  };

  const handleFormSubmit = () => {
    saveConfig(formValues);
  };

  if (isCategoryLoading || isCoatingLoading || isWageLoading) {
    return (
      <Typography>
        <LoadingSpinner />
      </Typography>
    );
  }

  return (
    <Box>
      <Box mb={3}>
        <FormControl fullWidth variant="outlined" sx={{ maxWidth: 300 }}>
          <InputLabel id="config-select-label">Configuration</InputLabel>
          <Select
            labelId="config-select-label"
            value={selectedConfig}
            onChange={(e) => setSelectedConfig(e.target.value)}
            label="Configuration"
          >
            <MenuItem value="measurementCategory">
              Measurement Category
            </MenuItem>
            <MenuItem value="coatingOptions">Coating Options</MenuItem>
            <MenuItem value="wage">Wage</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mb={2}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleAddClick}
        >
          Add
        </Button>
      </Box>

      <Box mb={3}>
        {isCategoryLoading || isCoatingLoading || isWageLoading ? (
          <LoadingSpinner />
        ) : (
          <DataGrid
            rows={rows}
            columns={[
              ...columns,
              selectedConfig !== "wage" && {
                field: "actions",
                headerName: "Actions",
                flex: 1,
                renderCell: (params) => (
                  <Box display="flex" gap={1}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleEditClick(params.row)}
                    >
                      Edit
                    </Button>
                  </Box>
                ),
              },
            ].filter(Boolean)}
            pageSize={10}
            rowsPerPageOptions={[5, 10]}
            disableSelectionOnClick
            getRowId={(row) => row.id || Math.random()}
            autoHeight
            components={{
              NoRowsOverlay: () =>
                selectedConfig === "wage" && rows.length === 0 ? (
                  <Typography>No wage found.</Typography>
                ) : (
                  <Typography>No data available.</Typography>
                ),
            }}
          />
        )}
      </Box>

      {isFormOpen && (
        <SettingsAddEditForm
          isOpen={isFormOpen}
          onClose={() => setIsFormOpen(false)}
          onSubmit={handleFormSubmit}
          formValues={formValues}
          onFormChange={(e) =>
            setFormValues({ ...formValues, [e.target.name]: e.target.value })
          }
          mode={formMode}
          columns={columns}
          validationErrors={validationErrors}
          isSaving={isSaving}
        />
      )}
    </Box>
  );
}
