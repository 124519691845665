import apiClient from "./apiClient";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

/**
 * Fetch the most recent wage configuration.
 */
export const fetchRecentWage = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/wage/recent`);
  return response.data;
};

export const fetchAllWages = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/wage`); // Adjust the endpoint path if needed
  return response.data.data; // Assuming the response structure is { data: { data: [...] } }
};

/**
 * Create a new wage configuration.
 * @param {Object} wage - The wage configuration to be created.
 */
export const createWage = async (wage) => {
  const response = await apiClient.post(`${API_BASE_URL}/wage`, wage);
  return response.data;
};

/**
 * Update an existing wage configuration.
 * @param {string} id - The ID of the wage configuration to be updated.
 * @param {Object} wage - The updated wage configuration data.
 */
export const updateWage = async (id, wage) => {
  const response = await apiClient.put(`${API_BASE_URL}/wage/${id}`, wage);
  return response.data;
};

/**
 * Delete an existing wage configuration.
 * @param {string} id - The ID of the wage configuration to be deleted.
 */
export const deleteWage = async (id) => {
  const response = await apiClient.delete(`${API_BASE_URL}/wage/${id}`);
  return response.data;
};
