import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DeleteOutline, ExpandMore } from "@mui/icons-material";

const MeasurementForm = ({
  measurements = { rooms: [], notes: "" },
  setMeasurements,
  coatingOptions = [],
  categoryPricing = [],
}) => {
  const [totalPaintableArea, setTotalPaintableArea] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [roomAreas, setRoomAreas] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  useEffect(() => {
    if (!measurements.rooms) {
      setMeasurements((prev) => ({ ...prev, rooms: [] }));
    }
  }, [measurements, setMeasurements]);

  useEffect(() => {
    calculatePaintableArea();
  }, [measurements.rooms]);

  useEffect(() => {
    if (measurements.rooms) {
      const updatedRooms = measurements.rooms.map((room) => ({
        ...room,
        coatings: room.coatings.map((coating) => ({
          ...coating,
          coatingOption: coatingOptions.find(
            (option) => option.coatingType === coating.coatingType
          ),
        })),
      }));
      setMeasurements((prev) => ({ ...prev, rooms: updatedRooms }));
    }
  }, [measurements.rooms, coatingOptions]);

  const calculateArea = ({
    length,
    breadth,
    height,
    includeCeiling = false,
  }) => {
    let wallArea = 0;
    let ceilingArea = 0;

    if (length && breadth && height) {
      wallArea = 2 * (length * height + breadth * height);
    } else if (length && breadth) {
      wallArea = length * breadth;
    } else if (length && height) {
      wallArea = length * height;
    } else if (breadth && height) {
      wallArea = breadth * height;
    }

    if (includeCeiling && length && breadth) {
      ceilingArea = length * breadth;
    }

    return wallArea + ceilingArea;
  };

  const calculateExclusionArea = (exclusion) => {
    const { length, breadth, height } = exclusion;
    let exclusionArea = 0;

    if (length && breadth && height) {
      exclusionArea =
        2 * (length * height + breadth * height) + length * breadth;
    } else if (length && height && (breadth == null || breadth === 0)) {
      exclusionArea = length * height;
    } else if (length && breadth && height == null) {
      exclusionArea = length * breadth;
    }

    return exclusionArea;
  };

  const calculatePaintableArea = () => {
    let totalArea = 0;
    let totalRoomPrice = 0;

    const updatedRooms = measurements.rooms.map((room) => {
      const roomArea = calculateArea(room);
      const exclusionArea =
        room.exclusions?.reduce(
          (acc, exclusion) => acc + calculateExclusionArea(exclusion),
          0
        ) || 0;
      const extraArea =
        room.extraAreas?.reduce(
          (acc, extra) => acc + calculateArea(extra),
          0
        ) || 0;

      const paintableArea = roomArea - exclusionArea + extraArea;
      const finalPaintableArea = Math.max(paintableArea, 0);

      const category = categoryPricing.find((c) => c.id === room.categoryId);
      const roomCategoryPrice = category?.pricePerSqft || 0;

      const roomPrice =
        finalPaintableArea *
        roomCategoryPrice *
        (room.coatings?.reduce(
          (acc, coating) => acc + coating.numberOfCoats,
          1
        ) || 1);

      totalArea += finalPaintableArea;
      totalRoomPrice += roomPrice;

      room.totalPaintableArea = finalPaintableArea;

      return {
        ...room,
        paintableArea: finalPaintableArea,
        price: roomPrice,
      };
    });
    setRoomAreas(updatedRooms);
    setTotalPrice(totalRoomPrice);
    setTotalPaintableArea(totalArea);
    setMeasurements((prev) => ({
      ...prev,
      rooms: updatedRooms,
      totalAreaSqft: totalArea,
      totalPrice: totalRoomPrice,
    }));
  };

  const handleInputChange = (value, field, roomIndex) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex][field] = value;
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleRoomScopeChange = (roomIndex, value) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].roomScope = value;
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleAddRoom = () => {
    const defaultCategory =
      categoryPricing.find((category) => category.id === selectedCategoryId) ||
      null;
    const newRoom = {
      roomType: "New Room",
      length: "",
      breadth: "",
      height: "",
      roomScope: "INTERIOR",
      exclusions: [],
      extraAreas: [],
      measurementCategory: defaultCategory
        ? {
            id: defaultCategory.id,
            categoryName: defaultCategory.categoryName,
          }
        : null,
      coatings: [],
      totalPaintableArea: 0,
      includeCeiling: false,
    };
    setMeasurements((prev) => ({
      ...prev,
      rooms: [...prev.rooms, newRoom],
    }));
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
    const updatedRooms = measurements.rooms.map((room) => {
      const selectedCategory = categoryPricing.find(
        (category) => category.id === categoryId
      );
      return {
        ...room,
        measurementCategory: selectedCategory
          ? {
              id: selectedCategory.id,
              categoryName: selectedCategory.categoryName,
            }
          : null,
      };
    });
    setMeasurements((prev) => ({
      ...prev,
      rooms: updatedRooms,
    }));
  };

  const handleRemoveRoom = (index) => {
    setMeasurements((prev) => ({
      ...prev,
      rooms: prev.rooms.filter((_, i) => i !== index),
    }));
  };

  const handleAddExclusion = (roomIndex) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].exclusions.push({
      exclusionType: "",
      length: "",
      height: "",
      breadth: "",
    });
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleRemoveExclusion = (roomIndex, exclusionIndex) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].exclusions = updatedRooms[
      roomIndex
    ].exclusions.filter((_, i) => i !== exclusionIndex);
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleAddExtraArea = (roomIndex) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].extraAreas.push({
      areaType: "",
      length: "",
      breadth: "",
      height: "",
    });
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleRemoveExtraArea = (roomIndex, extraAreaIndex) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].extraAreas = updatedRooms[
      roomIndex
    ].extraAreas.filter((_, i) => i !== extraAreaIndex);
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleAddCoating = (roomIndex) => {
    const newCoatingType = coatingOptions[0]?.id || ""; // Default to the first coating option
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].coatings.push({
      coatingOption: coatingOptions.find(
        (option) => option.id === newCoatingType
      ),
      numberOfCoats: 1,
    });
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleRemoveCoating = (roomIndex, coatingIndex) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].coatings = updatedRooms[roomIndex].coatings.filter(
      (_, i) => i !== coatingIndex
    );
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  const handleCoatingChange = (roomIndex, coatingIndex, coatingId) => {
    // Clone the current rooms
    const updatedRooms = [...measurements.rooms];

    // Find the selected coating option
    const selectedCoating = coatingOptions.find(
      (option) => option.id === coatingId
    );

    if (selectedCoating) {
      updatedRooms[roomIndex].coatings[coatingIndex] = {
        ...updatedRooms[roomIndex].coatings[coatingIndex],
        coatingType: selectedCoating.coatingType, // Update coatingType
        coatingOption: selectedCoating, // Update coatingOption object
      };

      setMeasurements({ ...measurements, rooms: updatedRooms });
    }
  };

  const handleCeilingChange = (roomIndex, includeCeiling) => {
    const updatedRooms = [...measurements.rooms];
    updatedRooms[roomIndex].includeCeiling = includeCeiling;
    setMeasurements({ ...measurements, rooms: updatedRooms });
  };

  return (
    <Box sx={{ mt: 3, p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3, color: "#1976d2" }}>
        Room Measurements & Exclusions
      </Typography>
      <Select
        value={selectedCategoryId}
        onChange={(e) => handleCategoryChange(e.target.value)}
        displayEmpty
        sx={{ mb: 3, width: "300px" }}
      >
        <MenuItem value="">
          <em>Select Category</em>
        </MenuItem>
        {categoryPricing.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.categoryName}
          </MenuItem>
        ))}
      </Select>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>Room Type</TableCell>
              <TableCell sx={{ width: "100px" }}>Room Scope</TableCell>
              <TableCell>Length (ft)</TableCell>
              <TableCell>Breadth (ft)</TableCell>
              <TableCell>Height (ft)</TableCell>
              <TableCell>Ceiling Included</TableCell>
              <TableCell>Category</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measurements.rooms.map((room, roomIndex) => (
              <React.Fragment key={roomIndex}>
                <TableRow>
                  <TableCell>
                    <TextField
                      value={room.roomType}
                      onChange={(e) =>
                        handleInputChange(e.target.value, "roomType", roomIndex)
                      }
                      placeholder="Room Type"
                      sx={{ width: "100%", fontSize: "0.9rem" }}
                    />
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      row
                      value={room.roomScope || "INTERIOR"}
                      onChange={(e) =>
                        handleRoomScopeChange(roomIndex, e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="INTERIOR"
                        control={<Radio />}
                        label="Interior"
                      />
                      <FormControlLabel
                        value="EXTERIOR"
                        control={<Radio />}
                        label="Exterior"
                      />
                    </RadioGroup>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={room.length || ""}
                      onChange={(e) =>
                        handleInputChange(e.target.value, "length", roomIndex)
                      }
                      placeholder="Length"
                      sx={{ width: "100%", fontSize: "0.9rem" }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={room.breadth || ""}
                      onChange={(e) =>
                        handleInputChange(e.target.value, "breadth", roomIndex)
                      }
                      placeholder="Breadth"
                      sx={{ width: "100%", fontSize: "0.9rem" }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={room.height || ""}
                      onChange={(e) =>
                        handleInputChange(e.target.value, "height", roomIndex)
                      }
                      placeholder="Height"
                      sx={{ width: "100%", fontSize: "0.9rem" }}
                    />
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      row
                      value={room.includeCeiling ? "Yes" : "No"}
                      onChange={(e) =>
                        handleCeilingChange(roomIndex, e.target.value === "Yes")
                      }
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                        sx={{ margin: 0 }}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                        sx={{ margin: 0 }}
                      />
                    </RadioGroup>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={
                        room.measurementCategory?.id ||
                        room.measurementCategoryId ||
                        ""
                      }
                      onChange={(e) => {
                        const updatedRooms = [...measurements.rooms];
                        const selectedCategory = categoryPricing.find(
                          (category) => category.id === e.target.value
                        );

                        updatedRooms[roomIndex] = {
                          ...room,
                          measurementCategory: selectedCategory
                            ? {
                                id: selectedCategory.id,
                                categoryName: selectedCategory.categoryName,
                              }
                            : null,
                          measurementCategoryId: e.target.value, // Store the ID for backend consistency
                        };

                        setMeasurements({
                          ...measurements,
                          rooms: updatedRooms,
                        });
                      }}
                      displayEmpty
                      sx={{ width: "100%", fontSize: "0.9rem" }}
                    >
                      <MenuItem value="">
                        <em>Select Category</em>
                      </MenuItem>
                      {categoryPricing.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.categoryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => handleRemoveRoom(roomIndex)}
                      color="error"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>

                {/* Exclusions */}
                <TableRow>
                  <TableCell colSpan={7}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Exclusions (Windows, Doors)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {room.exclusions.map((exclusion, exclusionIndex) => (
                          <Box
                            key={exclusionIndex}
                            sx={{ display: "flex", gap: 1, mb: 1 }}
                          >
                            <TextField
                              label="Type"
                              value={exclusion.exclusionType || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].exclusions[
                                  exclusionIndex
                                ].exclusionType = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "150px", fontSize: "0.9rem" }}
                            />
                            <TextField
                              type="number"
                              label="Length"
                              value={exclusion.length || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].exclusions[
                                  exclusionIndex
                                ].length = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "100px", fontSize: "0.9rem" }}
                            />
                            <TextField
                              type="number"
                              label="Breadth"
                              value={exclusion.breadth || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].exclusions[
                                  exclusionIndex
                                ].breadth = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "100px", fontSize: "0.9rem" }}
                            />
                            <TextField
                              type="number"
                              label="Height"
                              value={exclusion.height || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].exclusions[
                                  exclusionIndex
                                ].height = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "100px", fontSize: "0.9rem" }}
                            />
                            <IconButton
                              onClick={() =>
                                handleRemoveExclusion(roomIndex, exclusionIndex)
                              }
                              color="error"
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Box>
                        ))}
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => handleAddExclusion(roomIndex)}
                        >
                          Add Exclusion
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>

                {/* Extra Areas */}
                <TableRow>
                  <TableCell colSpan={7}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Extra Areas (Lofts, Pillars)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {room.extraAreas.map((extra, extraIndex) => (
                          <Box
                            key={extraIndex}
                            sx={{ display: "flex", gap: 1, mb: 1 }}
                          >
                            <TextField
                              label="Type"
                              value={extra.areaType || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].extraAreas[
                                  extraIndex
                                ].areaType = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "150px", fontSize: "0.9rem" }}
                            />
                            <TextField
                              type="number"
                              label="Length"
                              value={extra.length || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].extraAreas[
                                  extraIndex
                                ].length = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "100px", fontSize: "0.9rem" }}
                            />
                            <TextField
                              type="number"
                              label="Breadth"
                              value={extra.breadth || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].extraAreas[
                                  extraIndex
                                ].breadth = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "100px", fontSize: "0.9rem" }}
                            />
                            <TextField
                              type="number"
                              label="Height"
                              value={extra.height || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].extraAreas[
                                  extraIndex
                                ].height = e.target.value;
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "100px", fontSize: "0.9rem" }}
                            />
                            <IconButton
                              onClick={() =>
                                handleRemoveExtraArea(roomIndex, extraIndex)
                              }
                              color="error"
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Box>
                        ))}
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => handleAddExtraArea(roomIndex)}
                        >
                          Add Extra Area
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>

                {/* Coatings */}
                <TableRow>
                  <TableCell colSpan={7}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Coatings</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {room.coatings.map((coating, coatingIndex) => (
                          <Box
                            key={coatingIndex}
                            sx={{ display: "flex", gap: 1, mb: 1 }}
                          >
                            <Select
                              value={coating.coatingOption?.id || ""} // Use the ID of the coatingOption as the value
                              onChange={(e) =>
                                handleCoatingChange(
                                  roomIndex,
                                  coatingIndex,
                                  e.target.value
                                )
                              }
                              sx={{ width: "150px", fontSize: "0.9rem" }}
                            >
                              {coatingOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.coatingType}
                                </MenuItem>
                              ))}
                            </Select>

                            <TextField
                              type="number"
                              label="Coats"
                              value={coating.numberOfCoats || ""}
                              onChange={(e) => {
                                const updatedRooms = [...measurements.rooms];
                                updatedRooms[roomIndex].coatings[
                                  coatingIndex
                                ].numberOfCoats = parseInt(e.target.value, 10);
                                setMeasurements({
                                  ...measurements,
                                  rooms: updatedRooms,
                                });
                              }}
                              sx={{ width: "100px", fontSize: "0.9rem" }}
                            />
                            <IconButton
                              onClick={() =>
                                handleRemoveCoating(roomIndex, coatingIndex)
                              }
                              color="error"
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Box>
                        ))}

                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => handleAddCoating(roomIndex)}
                        >
                          Add Coating
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddRoom}
        >
          Add Room
        </Button>
      </Box>

      <Box
        sx={{
          mt: 3,
          p: 2,
          backgroundColor: "#f0f4f8",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "#1976d2", mb: 2, textAlign: "center" }}
        >
          Paintable Area Summary
        </Typography>
        {roomAreas.length > 0 ? (
          <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
            <Table size="small" sx={{ backgroundColor: "#ffffff" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#e0e0e0" }}>
                  <TableCell>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Room Type
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Paintable Area (sq ft)
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roomAreas.map((area, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}
                  >
                    <TableCell>
                      <Typography variant="body2">{area.roomType}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {area.paintableArea} sq ft
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No rooms available</Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography
          variant="h6"
          sx={{ mt: 2, fontWeight: "bold", textAlign: "center" }}
        >
          <strong>Total Paintable Area:</strong> {totalPaintableArea} sq ft
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h6">Notes</Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          value={measurements.notes || ""}
          onChange={(e) =>
            setMeasurements({ ...measurements, notes: e.target.value })
          }
          placeholder="Add any notes here..."
          sx={{ mt: 1 }}
        />
      </Box>
    </Box>
  );
};

export default MeasurementForm;
