import { useState } from "react";
import { useMutation } from "react-query";
import { useAuth } from "../../context/AuthContext";
import { sendOtp, verifyOtp } from "../../services/loginApi";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [autoSelectedCompanyName, setAutoSelectedCompanyName] = useState("");

  const sendOtpMutation = useMutation(sendOtp, {
    onSuccess: (data) => {
      if (data && data.data) {
        setCompanies(data.data); // Set the list of companies
        setIsOtpSent(true);
        setError("");
        setSuccessMessage(`OTP sent to ${mobileNumber}.`);
        if (data.data.length === 1) {
          // Automatically select the company if there's only one
          setSelectedCompany(data.data[0].id);
          setAutoSelectedCompanyName(data.data[0].companyName);
          setSuccessMessage(`OTP sent to ${mobileNumber}`);
        }
      } else {
        setError("Failed to fetch companies. Please try again.");
        setSuccessMessage("");
      }
    },
    onError: () => {
      setError("Failed to send OTP. Please try again.");
      setSuccessMessage("");
    },
  });

  const verifyOtpMutation = useMutation(verifyOtp, {
    onSuccess: (data) => {
      if (data) {
        login(data.accessToken, data.refreshToken, data.user);
        setSuccessMessage("Login successful!");
        setError("");
        navigate("/");
      } else {
        setError("Invalid OTP. Please try again.");
        setSuccessMessage("");
      }
    },
    onError: () => {
      setError("Failed to verify OTP. Please try again.");
      setSuccessMessage("");
    },
  });

  const handleSendOtp = () => {
    if (mobileNumber.length === 10) {
      sendOtpMutation.mutate(mobileNumber);
    } else {
      setError("Please enter a valid 10-digit mobile number.");
    }
  };

  const handleVerifyOtp = () => {
    if (otp.length === 6 && selectedCompany) {
      verifyOtpMutation.mutate({
        mobileNumber,
        otp,
        companyId: selectedCompany,
      });
    } else {
      setError("Please enter a valid OTP and select a company.");
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>

        <Box component="form" sx={{ mt: 3, width: "100%" }} noValidate>
          <TextField
            fullWidth
            required
            id="mobileNumber"
            label="Mobile Number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 10 }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSendOtp}
            disabled={sendOtpMutation.isLoading || isOtpSent}
            sx={{ mt: 2 }}
          >
            {sendOtpMutation.isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Send OTP"
            )}
          </Button>

          {isOtpSent && (
            <>
              {companies.length === 1 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  Company: <strong>{autoSelectedCompanyName}</strong>
                </Typography>
              )}

              {companies.length > 1 && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="company-select-label">
                    Select Company
                  </InputLabel>
                  <Select
                    labelId="company-select-label"
                    id="company-select"
                    value={selectedCompany}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                  >
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <TextField
                fullWidth
                required
                id="otp"
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 6 }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleVerifyOtp}
                disabled={verifyOtpMutation.isLoading}
                sx={{ mt: 2 }}
              >
                {verifyOtpMutation.isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Verify OTP"
                )}
              </Button>
            </>
          )}

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
