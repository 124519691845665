import { Box, Typography, Button, TextField, Modal } from "@mui/material";

const SettingsAddEditForm = ({
  isOpen,
  onClose,
  onSubmit,
  formValues,
  onFormChange,
  mode = "Add",
  columns = [],
  validationErrors = {}, // Default to an empty object
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        p={4}
        sx={{
          width: 600,
          maxHeight: 500,
          overflowY: "auto",
          margin: "100px auto",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {mode === "Add" ? "Add New Item" : "Edit Item"}
        </Typography>
        {columns
          .filter((col) => col.editable || col.disableInEditMode)
          .map((col) => (
            <Box key={col.field} mb={2}>
              <TextField
                label={`${col.headerName} ${col.required ? "*" : ""}`} // Add * for required fields
                name={col.field}
                fullWidth
                margin="normal"
                value={formValues[col.field] || ""}
                onChange={(e) => onFormChange(e)}
                error={!!validationErrors[col.field]} // Highlight if there are validation errors
                helperText={
                  validationErrors[col.field] || "" // Display error messages
                }
                disabled={
                  mode === "Edit" && col.disableInEditMode // Disable in edit mode if required
                }
                required={col.required} // Mark as required if specified in columns
              />
            </Box>
          ))}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={onSubmit}
            disabled={Object.values(validationErrors).some(
              (error) => error // Disable if there are validation errors
            )}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SettingsAddEditForm;
